<template>
  <div class="" v-if="debitNote">
    <TopBarTitleComponent
      title="Información Nota de débito"
      :showBackIcon="true"
    >
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <el-dropdown
            @command="handleCommand"
            class="dropdown-theme-gray-outline d-flex align-items-center mr-4"
          >
            <span class="el-dropdown-link">
              <span class="ml-2">Opciones</span>
              <i class="el-icon-arrow-down el-icon--right mr-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template
                v-if="
                  debitNote.status === 'draft' || debitNote.status === 'failed'
                "
              >
                <el-dropdown-item command="debitNote-edit"
                  >Editar</el-dropdown-item
                >
              </template>

              <el-dropdown-item
                v-if="
                  debitNote.status !== 'success' && debitNote.status !== 'nuled'
                "
                command="debit-note-delete"
              >
                Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <ButtonPrimaryComponent
            v-if="
              debitNote.status !== 'success' && debitNote.status !== 'nuled'
            "
            title="Firmar"
            @onSaveClick="toogleModalDocumentSRI"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4 pt-4">
      <b-row v-if="debitNote.sri_sign_message">
        <b-col>
          <AlertInfoComponent
            title="Notificación SRI"
            type="error"
            class="mb-4"
            :description="debitNote.sri_sign_message"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="card box-shadow card-quotes-view py-4">
            <div class="card-body">
              <div class="card-quotes-view-header">
                <b-row>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-logo mb-4">
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="4">
                          <div class="logo">
                            <img
                              v-if="getUser.user_business.business_logo"
                              :src="getUser.user_business.business_logo"
                              class="image"
                              id="image-business"
                              alt="Empresa"
                              width="200px"
                            />
                          </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="7" lg="8">
                          <div class="info">
                            <h4 class="h4 font-bold m-0">
                              N° {{ debitNote.identification_document }}
                            </h4>
                            <h2 class="h2 font-bold m-0"></h2>
                            <p class="font-semi-bold mt-2">
                              RUC: {{ getUser.user_business.business_ruc }}
                            </p>
                            <p class="font-semi-bold">
                              {{ getUser.user_business.business_address }}
                            </p>

                            <SignAuthorizationInfo
                              class="mt-4"
                              :accessKey="debitNote.access_key"
                              :signMode="debitNote.sign_mode"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-info">
                      <b-row>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Fecha de emisión</label
                          >
                          <p class="m-0">
                            {{ debitNote.emission_date_for_view }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Documento modificar</label
                          >
                          <p class="m-0">
                            {{ debitNote.invoice.number_invoice }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block mb-2"
                            >Status</label
                          >
                          <StatusReadDocumentsComponent
                            :status.sync="debitNote.status"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Cliente</label>
                          <p class="m-0">
                            {{ debitNote.invoice.client.name }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Documento</label
                          >
                          <p class="m-0">
                            {{ debitNote.invoice.client.document }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Correo</label>
                          <p class="m-0">
                            {{ debitNote.invoice.client.email }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="card-quotes-view-body mt-5">
                <div class="card-quotes-view-body-products">
                  <div class="card-quotes-view-table table-items">
                    <b-row class="table-items-header">
                      <b-col xs="12" sm="12" md="1">
                        <h6 class="h6 font-bold mb-0 ml-2">Código</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="5">
                        <h6 class="h6 font-bold mb-0">Detalle</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Cantidad</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Precio U.</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Subtotal</h6>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row
                    class="mt-4 d-flex"
                    v-for="(product, index) in debitNote.products"
                    :key="index"
                  >
                    <b-col xs="12" sm="12" md="1">
                      <p class="m-0 ml-2">{{ product.product.code }}</p>
                    </b-col>
                    <b-col xs="12" sm="12" md="5">
                      <p class="m-0">
                        <strong>{{ product.product.name }}</strong> <br />
                        <br />
                        <span
                          v-html="product.product_detail"
                          class="text-pre-wrap"
                        ></span>
                      </p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">{{ product.product_quantity }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">${{ product.product_amount }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">
                        ${{
                          handleCalculateSubTotal(
                            product.product_quantity,
                            product.product_amount
                          )
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <b-row>
                  <b-col xs="12" sm="12" md="6"></b-col>
                  <b-col xs="12" sm="12" md="4" offset-md="2">
                    <DocumentCalculatorNotes
                      :setProducts="debitNote.products"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="debitNoteSRIOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-signature">
        <h4 class="h4 text-center font-semi-bold text-break text-color-primary">
          ¿Desea firmar y enviar el documento al SRI?
        </h4>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <button type="button" class="btn md__btn-third-secondary mr-4">
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Confirmar"
            @onSaveClick="sendDebitNoteSRI"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="debitNoteDeleteOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-delete text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea eliminar la nota de débito?
        </h4>

        <p class="text-color-primary text-break">
          Recuerde que solo puede eliminar las notas de débito en borrador.
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalDebitNoteDelete"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Eliminar"
            @onSaveClick="onDebitNoteDelete"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AlertInfoComponent from "@/components/Alerts/Info";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import debitNoteService from "../services/debitNoteService";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import SignAuthorizationInfo from "../../../components/SignAuthorizationInfo";
import DocumentCalculatorNotes from "../../../components/DocumentCalculatorNotes";

import { parseFloatFormat } from "@/assets/utils/format";
import { icoNotifyDanger } from "@/services/resources";

export default {
  name: "DebitNoteViewPage",
  data: () => ({
    icoNotifyDanger,
    debitNoteSRIOuterVisible: false,
    debitNoteDeleteOuterVisible: false,
    debitNote: null
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    handleCalculateSubTotal(quantity, price) {
      return parseFloatFormat(quantity * price);
    },
    goPage(name) {
      this.$router.push({ name });
    },
    toogleModalDocumentSRI() {
      this.debitNoteSRIOuterVisible = !this.debitNoteSRIOuterVisible;
    },
    async sendDebitNoteSRI() {
      this.$store.dispatch("toggleRequestLoading", {
        text:
          "Por favor espere un momento, su documento esta siendo enviado al SRI."
      });
      try {
        this.toogleModalDocumentSRI();
        let response = await debitNoteService
          .sendDebitNoteSRI({
            user_business_debit_note_id: this.debitNote.id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        } else {
          this.$notifications.error({
            message
          });
        }
        this.debitNote = data;
      } catch (error) {
        return false;
      }
    },
    async getDebitNoteId(debitNoteId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await debitNoteService
          .getDebitNoteId(debitNoteId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) {
          this.debitNote = data;
        }
      } catch (error) {
        return false;
      }
    },
    async onDebitNoteDelete() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: debitNoteId } = this.debitNote;
        let response = await debitNoteService
          .deleteDebitNoteId(debitNoteId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalDebitNoteDelete();
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.goPage("sales.documents");
        }
      } catch (error) {
        return false;
      }
    },
    handleCommand(command) {
      switch (command) {
        case "debit-note-edit":
          this.goPage("sales.invoices.edit", {
            id: this.invoice.id
          });
          break;
        case "debit-note-delete":
          this.toggleModalDebitNoteDelete();
          break;
      }
    },
    toggleModalDebitNoteDelete() {
      this.debitNoteDeleteOuterVisible = !this.debitNoteDeleteOuterVisible;
    }
  },
  components: {
    TopBarTitleComponent,
    AlertInfoComponent,
    ButtonPrimaryComponent,
    StatusReadDocumentsComponent,
    SignAuthorizationInfo,
    DocumentCalculatorNotes
  },
  mounted() {
    const { id: debitNoteId } = this.$route.params;
    if (debitNoteId) {
      this.getDebitNoteId(debitNoteId);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-quotes-view {
  .card-quotes-view-header {
    .card-quotes-view-logo {
      p {
        margin: 0;
      }
    }
  }
  .card-quotes-view-body-products {
    min-height: 300px;
  }
}
</style>
